import { ActionIcon, Box, Button, Grid } from '@mantine/core'
import { useState } from 'react'
import { useLocation } from 'wouter'
import { IconArrowBack, IconBeneficiaryAdd } from '../../../assets/icons'
import AppHeader from '../../../components/layout/AppHeader'
import AppWrapper from '../../../components/layout/AppWrapper'
import Container from '../../../components/layout/Container'
import BeneficiaryList from './BeneficiaryList'
import SearchIndividual from './SearchIndividual'
import { openModalForAddBeneficiary } from '../../../components/modals/AddBeneficiary'

const BeneficiaryCentre = () => {
  const [, navigate] = useLocation()

  // const [nameFilter, setNameFilter] = useState()
  const [countryFilter, setCountryFilter] = useState<string>('')
  const [statusFilter, setStatusFilter] = useState<string | null>('active')

  return (
    <AppWrapper
      title='Card Noir - Beneficiary Centre'
      appHeader={
        <AppHeader
          title='Manage Beneficiaries'
          description='Add and manage beneficiaries'
          icon={<IconArrowBack height='1.5em' />}
          iconOnClick={() => {
            navigate('~/dashboard')
          }}
          buttons={[
            <Button
              key='add-beneficiary'
              size='md'
              variant='cardnoir'
              leftSection={<IconBeneficiaryAdd height='1.45em' />}
              color='primary.6'
              style={{ justifySelf: 'start' }}
              visibleFrom='md'
              onClick={openModalForAddBeneficiary()}
            >
              <Box>Add beneficiary</Box>
              <Box>Add a beneficiary to your list</Box>
            </Button>,
            <ActionIcon key='add-benef-2' color='primary.6' variant='outline' size='md' hiddenFrom='md' onClick={openModalForAddBeneficiary()}>
              <IconBeneficiaryAdd height='1.45em' />
            </ActionIcon>,
          ]}
        />
      }
    >
      <Container>
        <Grid py={{ base: 'xl', md: '3rem' }} gutter={{ base: 'xl', md: '3rem' }} align='start'>
          <Grid.Col span={{ base: 12, md: 4 }}>
            <SearchIndividual countryFilter={countryFilter} setCountryFilter={setCountryFilter} statusFilter={statusFilter} setStatusFilter={setStatusFilter} />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 8 }}>
            <BeneficiaryList />
          </Grid.Col>
        </Grid>
      </Container>
    </AppWrapper>
  )
}

export default BeneficiaryCentre
