import { Button, Divider, Flex, Grid, Select, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { ContextModalProps, modals } from '@mantine/modals';
import { useContext } from 'react';
import { IconCloseDeleteNo, IconFundsSend } from '../../assets/icons';
import { AuthContext, SessionContext } from '../../contexts';
import { isRequired } from '../../routes/onboarding/_common/form-utils';
import ModalWrapper from './ModalWrapper';

const SendFunds = ({ context, id, innerProps }: ContextModalProps<{ selectedAccount: string; selectedBeneficiary: string }>) => {
  const { session } = useContext(SessionContext);
  const { user } = useContext(AuthContext);

  const accounts = user.accounts.sort((c1, c2) => c1.name.localeCompare(c2.name));
  const beneficiaries = session.beneficiaries.sort((c1, c2) => c1.name.localeCompare(c2.name));

  const form = useForm({
    initialValues: {
      account: innerProps.selectedAccount ?? accounts[0]?.id ?? '',
      beneficiary: innerProps.selectedBeneficiary ?? '',
      amount: '',
      reference: '',
    },
    validate: {
      beneficiary: isRequired,
      account: isRequired,
      amount: isRequired,
      reference: isRequired,
    },
    validateInputOnBlur: true,
  });

  const send = () => {
    form.validate();
    if (form.isValid()) {
    }
  };

  return (
    <ModalWrapper title='Send funds from your account' onClose={() => context.closeModal(id)}>
      <Grid gutter='md'>
        <Grid.Col span={12}>
          <Select
            readOnly={!!innerProps.selectedAccount}
            styles={{ input: { color: innerProps.selectedAccount ? 'var(--mantine-color-success-6)' : undefined } }}
            label='Select source account'
            data={accounts.map((acc) => ({ label: acc.name, value: acc.id }))}
            description='Funds will be transferred in EUR currency and are valid up to 50 000 EUR per transaction'
            {...form.getInputProps('account')}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Select
            readOnly={!!innerProps.selectedBeneficiary}
            styles={{ input: { color: innerProps.selectedBeneficiary ? 'var(--mantine-color-success-6)' : undefined } }}
            label='Beneficiary'
            data={beneficiaries.map((ben) => ({ label: ben.name, value: ben.id }))}
            description='Recipient of the funds'
            {...form.getInputProps('beneficiary')}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <TextInput label='Amount' id='amount' description='Enter the amount you want to send' {...form.getInputProps('amount')} />
        </Grid.Col>
        <Grid.Col span={12}>
          <TextInput label='Reference' id='reference' description='Enter a reference for the beneficiary to recognize' {...form.getInputProps('reference')} />
        </Grid.Col>
      </Grid>

      <Divider size='sm' my='lg' color='dark.6' />
      <Flex justify='end' gap='md'>
        <Button variant='outline' color='error.3' onClick={() => context.closeModal(id)} leftSection={<IconCloseDeleteNo height='1em' />}>
          Cancel
        </Button>
        <Button variant='outline' color='success.3' leftSection={<IconFundsSend height='1em' />} onClick={send}>
          Send
        </Button>
      </Flex>
    </ModalWrapper>
  );
};

export function openModalForSendFunds(props?: { onClose?: () => void; selectedAccount?: string; selectedBeneficiary?: string }) {
  return () =>
    modals.openContextModal({
      modal: 'sendFunds',
      withCloseButton: false,
      size: 'lg',
      onClose: props?.onClose,
      innerProps: {
        selectedAccount: props?.selectedAccount,
        selectedBeneficiary: props?.selectedBeneficiary,
      },
    });
}

export default SendFunds;
