import { Box, BoxProps, Anchor, Text, Group } from '@mantine/core'
import Container from './Container'
import classes from './AppFooter.module.css'
import { IconMasterCard, IconVisaCard } from '../../assets/icons'

export const AppFooter = (props: BoxProps) => {
  return (
    <Box {...props} className={classes.root + ' ' + props.className}>
      <Container className={classes.container}>
        <Box className={classes.linksWrapper}>
          <Anchor>PRIVACY POLICY</Anchor>
          <Anchor>TERMS &amp; CONDITIONS</Anchor>
        </Box>
        <Text className={classes.legalWrapper}>
          © Card Noir. All rights reserved. Card Noir, (address)(registration country). Card Noir is authorised to ...Ex velit dolore voluptate eu et. Voluptate
          et esse aliquip qui id eiusmod culpa Lorem veniam ut. Culpa sint exercitation et mollit nisi amet sunt eiusmod fugiat sunt labore.
        </Text>
        <Group className={classes.iconWrapper}>
          <IconMasterCard className={classes.masterCardIcon} />
          <IconVisaCard className={classes.visaIcon} />
        </Group>
      </Container>
    </Box>
  )
}

export default AppFooter