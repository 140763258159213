import React from 'react'
import { createRoot } from 'react-dom/client'
import {
  CSSVariablesResolver,
  MantineProvider,
  VariantColorsResolver,
  defaultVariantColorsResolver,
  parseThemeColor,
  rem,
  rgba
} from '@mantine/core'

// Styles
import '@mantine/core/styles.css'
import '@mantine/dates/styles.css'
import '@mantine/carousel/styles.css'
import './index.css'

// Theme
import mantineTheme from './theme/mantineTheme'

// Components
import App from './App'

// Date utilities configuration
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import timezone from 'dayjs/plugin/timezone'

/**
 * Configure dayjs plugins
 */
const configureDayjs = () => {
  dayjs.extend(advancedFormat)
  dayjs.extend(timezone)
  dayjs.extend(customParseFormat)
  dayjs.extend(isSameOrBefore)
}

/**
 * CSS variables resolver for theme customization
 */
const cssVariablesResolver: CSSVariablesResolver = (theme) => {
  const commonVariables = {
    '--mantine-color-primary-text': theme.colors.primary[6],
    '--mantine-color-body': theme.colors.dark[7],
    '--mantine-color-text': theme.white,
    '--mantine-color-dimmed': theme.colors.dark[0],
    '--mantine-color-error': theme.colors.error[4],
  }

  return {
    variables: commonVariables,
    light: commonVariables,
    dark: commonVariables,
  }
}

/**
 * Custom variant color resolver for special card styles
 */
const variantColorResolver: VariantColorsResolver = (input) => {
  const defaultResolvedColors = defaultVariantColorsResolver(input)
  const parsedColor = parseThemeColor({
    color: input.color || input.theme.primaryColor,
    theme: input.theme,
  })

  const variants: Record<string, ReturnType<VariantColorsResolver>> = {
    'cardnoir': {
      background: 'transparent',
      hover: rgba(parsedColor.value, 0.15),
      border: `${rem(2)} solid ${parsedColor.value}`,
      color: parsedColor.value,
    },
    'cardnoir-white': {
      background: 'transparent',
      hover: rgba(parsedColor.value, 0.15),
      border: `${rem(2)} solid ${parsedColor.value}`,
      color: 'var(--mantine-color-nebulous-0)',
    },
    'vertical': {
      background: 'transparent',
      hover: rgba(parsedColor.value, 0.15),
      border: `${rem(2)} solid ${parsedColor.value}`,
      color: 'var(--mantine-color-white-0)',
    },
  }

  return variants[input.variant] || defaultResolvedColors
}

/**
 * Initialize the application
 */
const initializeApp = () => {
  configureDayjs()

  const container = document.getElementById('root')
  if (!container) {
    throw new Error('Root element not found')
  }

  const root = createRoot(container)

  root.render(
    <React.StrictMode>
      <MantineProvider
        theme={{ ...mantineTheme, variantColorResolver }}
        defaultColorScheme='dark'
        cssVariablesResolver={cssVariablesResolver}
      >
        <App />
      </MantineProvider>
    </React.StrictMode>
  )
}

// Start the application
initializeApp()