.iconButtonWrapper {
  position: fixed;
  bottom: 7rem;
  right: 0;
  width: 5rem;
  height: 5rem;
  padding: 0;
  margin: 0;
  border-style: none;
  background: unset;
  cursor: pointer;
  &:hover {
    svg {
      filter: drop-shadow(0px 0px 1px var(--mantine-color-primary-6));
    }
  }

  display: none;
  @media (min-width: $mantine-breakpoint-md) {
    display: flex;
  }
}

.chatIconWrapper {
  height: 50%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--mantine-color-dark-7);
  border-radius: 0.3125rem 0px 0px 0px;
  border-top: 1pt solid var(--mantine-color-primary-3);
  border-bottom: 1pt solid var(--mantine-color-primary-3);
  border-left: 1pt solid var(--mantine-color-primary-3);
}

.desktopChatIcon {
  height: 1.875rem;
  fill: var(--mantine-color-primary-6);
}

.desktopContactSupportWrapper {
  display: flex;
  height: 50%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: var(--mantine-color-primary-3);
  color: var(--mantine-color-dark-7);
  line-height: 1.1;
  font-size: 0.875rem;
  font-weight: bold;
  border-radius: 0px 0px 0px 0.3125rem;
}

.mobileBtnHeader {
  color: #fff;
  font-size: 1rem;
}

.mobileBtnDescription {
  font-size: 0.75rem;
}
