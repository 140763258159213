import { Box, CloseButton, Grid, ScrollArea, Title } from '@mantine/core'

interface ModalWrapperProps {
  onClose: () => void
  title?: string
  themeColor?: string
  footer?: React.ReactNode
  children:React.ReactNode
}

const ModalWrapper: React.FC<ModalWrapperProps> = ({ title = '', onClose, children, footer }) => {
  return (
    <Box py="xs">
      <Grid align="center" mt="xs" mb="md" px="xs">
        <Grid.Col span={11}>
          <Title order={2} size="h2" c="primary" ta="center">
            {title}
          </Title>
        </Grid.Col>
        <Grid.Col span={1}>
          <CloseButton size="lg" onClick={onClose} variant="subtle" />
        </Grid.Col>
      </Grid>

      <ScrollArea.Autosize 
        offsetScrollbars 
        mah={{ md: 'calc(100dvh - (5dvh * 2) - 8rem)' }} 
        ml="sm" 
        px={{ md: 'xs' }}
      >
        <Box 
          bd="2pt solid var(--mantine-color-primary-3)" 
          style={{ borderRadius: 'var(--mantine-radius-md)' }} 
          p="md"
        >
          {children}
        </Box>
        {footer && (
          <Box h="2rem" mt="md">
            {footer}
          </Box>
        )}
      </ScrollArea.Autosize>
    </Box>
  )
}

export default ModalWrapper