import ChatIcon from '../../assets/icons/Icon_Contact_Us.svg'
import { Box, Button, Stack } from '@mantine/core'
import classes from './ChatButton.module.css'

const ChatButton = ({ className }: { className?: string }) => {
  return (
    <Box pos="relative" className={className}>
      <Button justify="center" className={classes.iconButtonWrapper} styles={{
        inner: {
          width: '100%'
        },
        label: {
          backgroundColor: 'yellow',
          display: 'flex',
          flexDirection: 'column',
          width: '100%'
        }
      }} variant='outline' visibleFrom='md' >
        <Box className={classes.chatIconWrapper}>
          <ChatIcon className={classes.desktopChatIcon} />
        </Box>
        <Box className={classes.desktopContactSupportWrapper}>
          Contact
          <br />
          Support
        </Box>
      </Button>
      <Button variant='outline' leftSection={<ChatIcon height='1.5em' />} color='primary' size='lg' w='100%' hiddenFrom='md'>
        <Stack gap={0} align='center' c='dark.0' p='2rem'>
          <Box className={classes.mobileBtnHeader}>Help, Contact Us &amp; Feedback</Box>
          <Box className={classes.mobileBtnDescription}>Reach out for help, and getting in touch</Box>
        </Stack>
      </Button>
    </Box>
  )
}

export default ChatButton
