import { Anchor, Box } from '@mantine/core'
import AppFooter from './AppFooter'
import Container from './Container'
import classes from './Footer.module.css'
import { IconSocialFacebook, IconSocialInstagram, IconSocialTwitter } from '../../assets/icons'

export const Footer = () => {
  return (
    <Box pos='relative'>
      <Box p='lg' bg='black'>
        <Container className={classes.topFooterContainer}>
          <Box className={classes.linksWrapper}>
            <Anchor>Home</Anchor>
            <Anchor>Card &amp; Pricing</Anchor>
            <Anchor>About us</Anchor>
          </Box>
          <Box className={classes.linksWrapper}>
            <Anchor>Customer support</Anchor>
            <Anchor>Contact us</Anchor>
            <Anchor>FAQ</Anchor>
          </Box>
          <Box className={classes.socialIconsWrapper}>
            <IconSocialTwitter />
            <IconSocialFacebook />
            <IconSocialInstagram />
          </Box>
        </Container>
      </Box>
      <AppFooter className={classes.appFooter} />
    </Box>
  )
}

export default Footer
