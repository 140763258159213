import { Box, Button, Flex, Image, PasswordInput, Text, TextInput, Grid } from '@mantine/core'
import { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useLocation } from 'wouter'
import CNCardVisa from '../assets/CN_card_Visa2_resized-min.png'
import API from '../backend/api'
import { Header } from '../components/common/Header'
import { PageEncryptedReminder } from '../components/common/PageEncryptedReminder'
import Container from '../components/layout/Container'
import Footer from '../components/layout/Footer'
import Navbar from '../components/layout/Navbar'
import { AuthContext } from '../contexts'
import classes from './SignIn.module.css'

const SignIn = () => {
  const [, navigate] = useLocation()
  const [loginError, setLoginError] = useState<string | undefined>(history.state?.errorMessage)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { token, setToken } = useContext(AuthContext)

  const tryLogin = async () => {
    try {
      const { token } = await API.authenticateUser({ email, password })
      setToken(token)
      setLoginError(undefined)
    } catch (err) {
      setLoginError((err as { message: string })?.message ?? 'Failed to process authentication. Please try again later.')
    }
  }

  useEffect(() => {
    if (token !== undefined && loginError === undefined) {
      navigate('~/member')
    }
  }, [token, loginError, navigate])

  return (
    <>
      <Helmet>
        <title>Card Noir - Sign In</title>
      </Helmet>
      <Navbar />
      <Grid className={classes.root}>
        <Grid.Col span={12} >
          <Header>
            Log into your <Box component='br' hiddenFrom='md' />
            Card Noir profile
          </Header>
        </Grid.Col>
        <Grid.Col span={12} >
          <Container>
            <Box maw={392} mx='auto' mod={{ opened: true }}>
              <Box pl='xl' pb='xl' w='100%' className={classes.formColumn}>
                <TextInput
                  mb={32}
                  type='email'
                  label='Email address'
                  id='email'
                  description='The email address linked to your profile'
                  onChange={(e) => setEmail(e.currentTarget.value)}
                  value={email}
                  autoFocus={true}
                  onKeyDown={(e) => e.key === 'Enter' && tryLogin()}
                />
                <PasswordInput
                  mb={32}
                  label='Password'
                  description='Enter your profile password to log in'
                  id='password'
                  onChange={(e) => setPassword(e.currentTarget.value)}
                  value={password}
                  onKeyDown={(e) => e.key === 'Enter' && tryLogin()}
                />
                <Text c='error' mt='0.4rem' size='xs' mb={16}>
                  {loginError}
                </Text>
                <Flex justify='space-between' align='center'>
                  <Button component={Link} variant='transparent' p='0' c='primary' to='/user/forgot-password'>
                    Forgot your password?
                  </Button>
                  <Button disabled={email === '' || password === ''} variant='outline' onClick={tryLogin} color='success' px={42}>
                    LOG IN
                  </Button>
                </Flex>
              </Box>
              <Box className={classes.formColumnFooter}>Don&apos;t have a Card Noir profile yet?</Box>
              <Flex justify='space-between' mt='md'>
                <Image w={140} ml={-16} mt={6} src={CNCardVisa} alt='' />
                <Flex align='center'>
                  <Button
                    variant='outline'
                    color='primary.6'
                    c='white'
                    onClick={() => {
                      navigate('~/sign-up')
                    }}
                    py={6}
                    style={{ height: 'auto' }}
                    size='lg'
                  >
                    <Text ta="center" lh={19}>
                      APPLY FOR
                      <br />
                      YOUR CARD TODAY
                    </Text>
                  </Button>
                </Flex>
              </Flex>
              <PageEncryptedReminder justify='center' />
            </Box>
          </Container>
        </Grid.Col>
        <Grid.Col span={12}>
          <Footer />
        </Grid.Col>
      </Grid>
    </>
  )
}

export default SignIn
