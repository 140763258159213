import { Box, Flex } from '@mantine/core'
import { useMemo } from 'react'
import { Flag } from '../../Flag'
import { Country, keyedCountries } from '../countries'
import { SearchableSelectItem, SearchableSelectProps } from './SearchableSelect'

const countries: readonly Country[] = Object.values(keyedCountries)

export const usePropsForCountrySelect = (): SearchableSelectProps => {
  const countriesOptions = useMemo(
    () =>
      countries.map(
        (item) =>
          ({
            value: item.alpha2code,
            label: item.name,
            labelNode: (
              <Flex gap='xs' align='center'>
                <Flag countryCode={item.alpha2code} />
                <Box>
                  {item.alpha2code} {item.name}
                </Box>
              </Flex>
            ),
            leftSectioNode: <Flag countryCode={item.alpha2code} />,
            searchString: `${item.alpha2code} ${item.name}`,
          } as SearchableSelectItem)
      ),
    [countries]
  )

  return {
    items: countriesOptions,
  }
}

export const usePropsForCountryDiallingCodeSelect = (): SearchableSelectProps => {
  const countriesOptions = useMemo(
    () =>
      countries.map(
        (item) =>
          ({
            value: item.alpha2code,
            label: item.phoneCode,
            labelNode: (
              <Flex gap='xs' align='center'>
                <Flag countryCode={item.alpha2code} />
                <Flex gap='xs'>
                  <Box w='4ch' ta='right'>
                    {item.phoneCode}
                  </Box>
                  {item.name}
                </Flex>
              </Flex>
            ),
            leftSectioNode: <Flag countryCode={item.alpha2code} />,
            searchString: `${item.phoneCode} ${item.name}`,
          } as SearchableSelectItem)
      ),
    [countries]
  )

  return {
    items: countriesOptions,
  }
}
