import { ActionIcon, Box, Button, Collapse, Flex, Grid, Group, Text } from '@mantine/core'
import { useToggle } from '@mantine/hooks'
import React from 'react'
import {
  IconArrowDropDown,
  IconBeneficiaryCardNoir,
  IconBeneficiaryNameBookmark,
  IconBeneficiarySEPA,
  IconBeneficiarySWIFT,
  IconCloseDeleteNo,
  IconEdit,
  IconFundsSend,
} from '../../../assets/icons'
import { Flag } from '../../../components/Flag'
import { getCountry } from '../../../components/input/countries'
import { openModalForSendFunds } from '../../../components/modals/SendFunds'
import formatIBAN from '../../../utils/formatIBAN'
import { Beneficiary as BeneficiaryInterface, IBANAccountDetails, isIBANAccountDetails } from '../../../utils/interfaces'

const paymentTypeDescription = {
  sepa: 'European IBAN - SEPA',
  swift: 'International SWIFT',
  cardnoir: 'Card Noir beneficiary',
}

const Beneficiary: React.FC<{
  data: BeneficiaryInterface
  isLastInGroup: boolean
  isEven: boolean
  className?: string
  setGroupFilter?: () => void
}> = ({ data, isLastInGroup, isEven }) => {
  const [opened, toggleOpened] = useToggle()

  return (
    <Box
      mb={isLastInGroup ? 'sm' : 0}
      bg={isEven ? 'dark.7' : 'dark.8'}
      style={{ cursor: 'pointer', borderBottom: '1pt solid var(--mantine-color-dark-2)' }}
      onClick={() => toggleOpened()}
    >
      <Grid px='sm' py='md' gutter={{ base: 'sm', md: 'md' }}>
        <Grid.Col span={{ base: 12, md: 10 }}>
          <Grid gutter="sm">
            <Grid.Col span={12}>
              <Grid>
                <Grid.Col span="content">
                  <Box w={24} c='white'>
                    {data.payment_type === 'swift' ? (
                      <IconBeneficiarySWIFT />
                    ) : data.payment_type === 'cardnoir' ? (
                      <IconBeneficiaryCardNoir />
                    ) : (
                      <IconBeneficiarySEPA />
                    )}
                  </Box>
                </Grid.Col>
                <Grid.Col span="auto">
                  <Flex direction={{ base: 'column', md: 'row' }} justify={{ md: 'space-between' }}>
                    <Text fw={700} inline lh={1.2}>
                      {data.alias}
                    </Text>
                    <Flex align='center' direction='row-reverse' gap='sm' fz='sm' visibleFrom='lg'>
                      <Box component={IconBeneficiaryNameBookmark} c='primary.3' height='1em' />
                      <Box>{data.name}</Box>
                    </Flex>
                    <Flex align='center' gap='sm' fz='sm' hiddenFrom='md'>
                      <Box component={IconBeneficiaryNameBookmark} c='primary.3' height='1em' />
                      <Box>{data.name}</Box>
                    </Flex>
                  </Flex>
                </Grid.Col>
              </Grid>
            </Grid.Col>
            <Grid.Col span={12}>
              <Grid>
                <Grid.Col span="auto">
                  <Box>
                    <Text fz='sm' lh={1}>
                      {paymentTypeDescription[data.payment_type]}
                    </Text>
                    {data.country && (
                      <Group wrap='nowrap' align='center' gap={4} mt={4}>
                        <Flag countryCode={data.country} style={{ height: 12, borderRadius: 2 }} />
                        <Text fz='sm'>{data.country}</Text>
                        <Text fz='sm'>{getCountry(data.country).name}</Text>
                      </Group>
                    )}
                  </Box>
                </Grid.Col>
                <Grid.Col span="content">
                  <Box>
                    <Text ta='right' fz='sm' lh={1}>
                      Default reference
                    </Text>
                    <Text ta='right' fz='sm' mt={4}>
                      {data.reference || '-'}
                    </Text>
                  </Box>
                </Grid.Col>
              </Grid>
            </Grid.Col>
          </Grid>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 2 }}>
          <Grid gutter="sm">
            <Grid.Col span={12}>
              <ActionIcon hiddenFrom='md' size='sm' variant='transparent' color='primary.3'>
                <IconArrowDropDown height='1em' />
              </ActionIcon>
              <Button
                variant='outline'
                visibleFrom='md'
                fullWidth
                color='success.3'
                leftSection={<IconFundsSend height='1em' />}
                size='xs'
                onClick={(e) => {
                  e.stopPropagation()
                  openModalForSendFunds({ selectedBeneficiary: data.id })()
                }}
              >
                <Text inline size='sm'>
                  Send funds
                </Text>
              </Button>
            </Grid.Col>
            <Grid.Col span={12}>
              <Button
                visibleFrom='md'
                autoContrast
                fullWidth
                color={opened ? 'primary' : 'dark.0'}
                variant={opened ? 'filled' : 'outline'}
                leftSection={
                  <Box component={IconArrowDropDown} h={12} style={{ transform: opened ? 'rotate(180deg)' : '', transition: 'transform 0.3s ease-in-out' }} />
                }
                size='xs'
                onClick={(e) => {
                  e.stopPropagation()
                  toggleOpened()
                }}
              >
                <Text inline size='sm'>
                  View details
                </Text>
              </Button>
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>
      <Collapse in={opened} transitionDuration={500}>
        <Box
          mx='sm'
          mb='sm'
          p='sm'
          fz='sm'
          c='nebulous.0'
          style={{
            border: '1pt dashed var(--mantine-color-primary-9)',
          }}
        >
          <Text fz='md' fw={700} mb='xs'>
            Beneficiary details
          </Text>
          <Grid>
            <Grid.Col span={{ base: 12, md: 8 }}>
              <Grid gutter="sm">
                <Grid.Col span="content">
                  <Box>Name</Box>
                  <Box>Contact</Box>
                  <Box>IBAN</Box>
                  <Box>Network</Box>
                </Grid.Col>
                <Grid.Col span="auto">
                  <Box>{data.name}</Box>
                  <Box>{data.email}</Box>
                  <Box>{isIBANAccountDetails(data.account_details) ? formatIBAN((data.account_details as IBANAccountDetails).account?.iban) : 'N/A'}</Box>
                  <Box>{data.payment_type}</Box>
                </Grid.Col>
              </Grid>
            </Grid.Col>
            <Grid.Col display="flex" span={{ base: 12, md: 4 }}>
              <Flex gap='sm' style={{ justifySelf: 'end', alignSelf: 'end' }} direction={{ base: 'column', lg: 'row' }} visibleFrom='md'>
                <Button variant='cardnoir' leftSection={<IconEdit height='1em' />} color='primary.3'>
                  <Box>Edit</Box>
                  <Box>beneficiary</Box>
                </Button>
                <Button variant='cardnoir' leftSection={<IconCloseDeleteNo height='1em' />} color='error.3'>
                  <Box>Delete</Box>
                  <Box>beneficiary</Box>
                </Button>
              </Flex>
              <Flex gap='sm' justify="center" align="center" hiddenFrom='md'>
                <Button variant='cardnoir' leftSection={<IconEdit height='1em' />} color='primary.3'>
                  <Box>Edit</Box>
                  <Box>beneficiary</Box>
                </Button>
                <Button variant='cardnoir' leftSection={<IconCloseDeleteNo height='1em' />} color='error.3'>
                  <Box>Delete</Box>
                  <Box>beneficiary</Box>
                </Button>
              </Flex>
            </Grid.Col>
          </Grid>
        </Box>
      </Collapse>
    </Box>
  )
}

export default Beneficiary