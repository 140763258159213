import { Box, Button, Container, Flex, PasswordInput, Text, TextInput, Title } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation, useParams } from 'wouter'

import API from '../../backend/api'
import { AuthContext } from '../../contexts'
import validatePassword from '../../utils/validatePassword'
import { chainIsRequired } from '../onboarding/_common/form-utils'
import Header from '../../components/common/Header'
import { PageEncryptedReminder } from '../../components/common/PageEncryptedReminder'
import Footer from '../../components/layout/Footer'
import Navbar from '../../components/layout/Navbar'
import classes from './ResetPassword.module.css'

const ResetPassword = () => {
  const [, navigate] = useLocation()
  const { token, setToken } = useContext(AuthContext)

  const params = useParams()
  const email = window.decodeURI(params.email || '')

  const form = useForm({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validateInputOnBlur: true,
    validate: {
      password: chainIsRequired((v) => validatePassword(email, v)),
      confirmPassword: chainIsRequired((val, values) => (val !== values.password ? 'The passwords do not match.' : null)),
    },
  })

  const [submitting, setSubmitting] = useState(false)
  const [resetError, setResetError] = useState<string | undefined>()

  const resetPassword = async () => {
    if (!submitting) {
      form.validate()
      if (form.isValid()) {
        setSubmitting(true)
        try {
          await API.resetPassword({ emailToken: params.token || '', email, password: form.values.password })
          const { token } = await API.authenticateUser({ email, password: form.values.password })
          setSubmitting(false)
          setToken(token)
        } catch (err) {
          setSubmitting(false)
          setResetError(err as string)
        }
      } else {
        window.scrollTo(0, document?.getElementById('top-of-form')?.getBoundingClientRect()?.top || 0)
      }
    }
  }

  /**
   * See CompleteSignUp.tsx for more info
   */
  useEffect(() => {
    if (form.isValid()) {
      navigate('~/member')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]) // we only need to run this when token is updated

  return (
    <>
      <Helmet>
        <title>Card Noir - Reset Password</title>
      </Helmet>
      <Navbar />
      <Box className={classes.root}>
        <Header>Enter your new password</Header>
        <Container>
          <Box maw={392} mx='auto'>
            <Box className={classes.formColumn}>
              <TextInput name='email' defaultValue={email} display='none' />
              <PasswordInput
                name='password'
                label='Password'
                description='Enter your password'
                mb={32}
                {...form.getInputProps('password')}
              />
              <PasswordInput
                name='confirmPassword'
                label='Confirm your password'
                description='Enter your password again to confirm'
                mb={32}
                {...form.getInputProps('confirmPassword')}
              />
              {resetError && <Text c='error' size='xs' mt='0.4rem' mb={16}>{resetError}</Text>}

              <Flex justify='center'>
                <Button disabled={!form.isValid()} tt='uppercase' onClick={resetPassword} color={form.isValid() || submitting ? 'success' : 'transparent'} px={42}>
                  RESET
                </Button>
              </Flex>
            </Box>
          </Box>
        </Container>
        <PageEncryptedReminder justify='center' />
        <Footer />
      </Box>
    </>
  )
}

export default ResetPassword
