import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { Grid } from '@mantine/core';
import AppFooter from './AppFooter';
import AppNavbar from './AppNavbar';

export const AppWrapper: React.FC<React.PropsWithChildren<{ title: string; appHeader: ReactNode }>> = ({ children, title, appHeader }) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <AppNavbar />
      <Grid mih='calc(100vh - 3.25rem)' gutter={0}>
        <Grid.Col span={12}>{appHeader}</Grid.Col>
        <Grid.Col span={12} style={{ flex: 1 }}>{children}</Grid.Col>
        <Grid.Col span={12}>
          <AppFooter />
        </Grid.Col>
      </Grid>
    </>
  );
};

export default AppWrapper;
