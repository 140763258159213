import { Box, Button, Chip, Divider, Flex, Grid, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { ContextModalProps, modals } from '@mantine/modals'
import { useContext, useState } from 'react'
import { IconCloseDeleteNo, IconFASave } from '../../assets/icons'
import { SessionContext } from '../../contexts'
import { isRequired } from '../../routes/onboarding/_common/form-utils'
import { PhoneNumberInput } from '../input/PhoneNumber'
import { SearchableSelect, usePropsForCountrySelect } from '../input/SearchableSelect'
import { EMAIL_REGEX, regexValidate } from '../input/email-validation'
import ModalWrapper from './ModalWrapper'

const AddBeneficiary = ({ context, id }: ContextModalProps) => {
  const [selectedBeneficiaryType, setSelectedBeneficiaryType] = useState('cardnoir')
  const propsForCountrySelect = usePropsForCountrySelect()
  const { dispatch } = useContext(SessionContext)

  const form = useForm({
    initialValues: {
      emailAddress: '',
      beneficiaryName: '',
      alias: '',
      popEmail: '',
      popMobile: '',
      destinationCountry: '',
      IBAN: '',
      swiftCode: '',
      beneficiaryAddress: {
        country_code: '',
        street1: '',
        street2: '',
        city: '',
        postal_code: '',
      },
      bankName: '',
      bankAddress: {
        country_code: '',
        street1: '',
        street2: '',
        city: '',
        postal_code: '',
      },
      phone_number_obj: null,
    },
    validate: {
      beneficiaryName: isRequired,
      alias: isRequired,

      emailAddress: (v) => (!v ? 'This field is required' : !regexValidate(v, true, EMAIL_REGEX) ? 'Please enter a valid email address' : null),

      destinationCountry: (v) => (selectedBeneficiaryType === 'cardnoir' ? null : isRequired(v)),
      IBAN: (v) => (selectedBeneficiaryType === 'cardnoir' ? null : isRequired(v)), // TODO: add better validation for iban number ?
      swiftCode: (v) => (selectedBeneficiaryType !== 'swift' ? null : isRequired(v)),

      beneficiaryAddress: {
        country_code: (v) => (selectedBeneficiaryType !== 'swift' ? null : isRequired(v)),
        street1: (v) => (selectedBeneficiaryType !== 'swift' ? null : isRequired(v)),
        city: (v) => (selectedBeneficiaryType !== 'swift' ? null : isRequired(v)),
      },

      bankName: (v) => (selectedBeneficiaryType !== 'swift' ? null : isRequired(v)),
      bankAddress: {
        country_code: (v) => (selectedBeneficiaryType !== 'swift' ? null : isRequired(v)),
        street1: (v) => (selectedBeneficiaryType !== 'swift' ? null : isRequired(v)),
        city: (v) => (selectedBeneficiaryType !== 'swift' ? null : isRequired(v)),
      },

      popEmail: (v) => (!v ? null : !regexValidate(v, true, EMAIL_REGEX) ? 'Please enter a valid email address' : null),
      phone_number_obj: (v) =>
        v === undefined || v === null || ((v as any).localPhoneNumber === '' && (v as any).diallingCode === '')
          ? null
          : (v as any)?.isValid
          ? null
          : 'Please enter a valid phone number',
    },
    validateInputOnBlur: true,
    validateInputOnChange: true,
    transformValues: (values) => ({
      ...values,
      popMobile: (values.phone_number_obj as any)?.formattedPhoneNumber ?? '',
    }),
  })

  const saveContact = () => {
    form.validate()
    if (form.isValid()) {
      if (selectedBeneficiaryType === 'cardnoir') {
        dispatch({
          type: 'addBeneficiary',
          beneficiary: {
            id: form.values.emailAddress,
            name: form.values.beneficiaryName,
            alias: form.values.alias,
            payment_type: 'cardnoir',
            entity_type: 'individual',
            currency: 'EUR',
            email: form.values.emailAddress,
            status: 'active',
            account_details: {
              name: form.values.alias,
              account_id: form.values.emailAddress,
            },
          },
        } as never)
      }
    }
  }

  return (
    <ModalWrapper title='Create a new beneficiary' onClose={() => context.closeModal(id)}>
      <Box mb='md' mt='xs'>
        <Chip.Group multiple={false} value={selectedBeneficiaryType} onChange={setSelectedBeneficiaryType}>
          <Flex gap='xs' justify='center' align='center' wrap='wrap'>
            <Chip value='cardnoir' radius='sm' size='md' color='success.6' autoContrast>
              With a Card Noir profile
            </Chip>
            <Chip value='iban' radius='sm' size='md' color='success.6' autoContrast>
              With an IBAN in Europe
            </Chip>
            <Chip value='swift' radius='sm' size='md' color='success.6' autoContrast>
              With an international account
            </Chip>
          </Flex>
        </Chip.Group>
      </Box>

      <Divider size='sm' my='lg' color='dark.6' />
      {selectedBeneficiaryType === 'cardnoir' && (
        <Grid gutter="md">
          <Grid.Col span={{ base: 12, md: 6 }}>
            <TextInput label='Email address' description='Enter the email address of the Card Noir contact.' {...form.getInputProps('emailAddress')} />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <TextInput label='Beneficiary name' description='Full name of the beneficiary on the account.' {...form.getInputProps('beneficiaryName')} />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <TextInput
              label='Alias'
              description='Easily find this contact by giving them a unique alias. Details will be saved in your beneficiary list.'
              {...form.getInputProps('alias')}
            />
          </Grid.Col>
        </Grid>
      )}
      {selectedBeneficiaryType === 'iban' && (
        <Grid gutter="md">
          <Grid.Col span={{ base: 12, md: 6 }}>
            <SearchableSelect
              {...propsForCountrySelect}
              label='Destination country'
              description='Country where you will be sending the funds.'
              {...form.getInputProps('destinationCountry')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <TextInput label='IBAN' description='International bank account number.' {...form.getInputProps('IBAN')} />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <TextInput label='Beneficiary name' description='Full name of the beneficiary on the account.' {...form.getInputProps('beneficiaryName')} />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <TextInput
              label='Alias'
              description='Easily find this contact by giving them a unique alias. Details will be saved in your beneficiary list.'
              {...form.getInputProps('alias')}
            />
          </Grid.Col>
        </Grid>
      )}
      {selectedBeneficiaryType === 'swift' && (
        <>
          <Grid gutter="md">
            <Grid.Col span={{ base: 12, md: 6 }}>
              <TextInput label='Beneficiary name' description='Full name of the beneficiary on the account.' {...form.getInputProps('beneficiaryName')} />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6 }}>
              <TextInput
                label='Alias'
                description='Easily find this contact by giving them a unique alias. Details will be saved in your beneficiary list.'
                {...form.getInputProps('alias')}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6 }}>
              <SearchableSelect
                label='Beneficiary address'
                description='Address of the recipient'
                {...form.getInputProps('beneficiaryAddress.country_code')}
                {...propsForCountrySelect}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6 }} hiddenFrom="md" />
            <Grid.Col span={{ base: 12, md: 6 }}>
              <TextInput description='Street name' {...form.getInputProps('beneficiaryAddress.street1')} />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6 }}>
              <TextInput description='Building, house name or suite number' {...form.getInputProps('beneficiaryAddress.street2')} />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6 }}>
              <TextInput description='City' {...form.getInputProps('beneficiaryAddress.city')} />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6 }}>
              <TextInput description='Postal code' {...form.getInputProps('beneficiaryAddress.postal_code')} />
            </Grid.Col>
          </Grid>
          <Divider size='md' my='lg' color='primary.3' />
          <Grid gutter="md">
            <Grid.Col span={{ base: 12, md: 6 }}>
              <TextInput label='Account number / IBAN' description='International bank account number.' {...form.getInputProps('IBAN')} />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6 }}>
              <TextInput label='SWIFT / BIC' description='Bank identification code.' {...form.getInputProps('swiftCode')} />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6 }}>
              <TextInput label='Bank name' description='Name of the bank you are transferring funds to' {...form.getInputProps('bankName')} />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6 }}>
              <SearchableSelect
                label='Bank address'
                description='Country where you will be sending the funds.'
                {...form.getInputProps('bankAddress.country_code')}
                {...propsForCountrySelect}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6 }}>
              <TextInput description='Street name' {...form.getInputProps('bankAddress.street1')} />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6 }}>
              <TextInput description='Building, house name or suite number' {...form.getInputProps('bankAddress.street2')} />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6 }}>
              <TextInput description='City' {...form.getInputProps('bankAddress.city')} />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6 }}>
              <TextInput description='Postal code' {...form.getInputProps('bankAddress.postal_code')} />
            </Grid.Col>
          </Grid>
        </>
      )}
      <Divider size='sm' my='lg' color='dark.6' />
      <Box fw={500} mb='xs' lh={1}>
        Proof of payment
      </Box>
      <Grid gutter="md">
        <Grid.Col span={{ base: 12, md: 6 }}>
          <TextInput
            label='Email address [optional]'
            description='Add a default email address to send a proof of payment to after a transaction.'
            {...form.getInputProps('popEmail')}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6 }}>
          <PhoneNumberInput
            label='Mobile phone number [optional]'
            description='Add a default mobile number to send a proof of payment text message to after a transaction.'
            {...form.getInputProps('phone_number_obj')}
          />
        </Grid.Col>
      </Grid>
      <Divider size='sm' my='lg' color='dark.6' />
      <Flex justify='end' gap='md'>
        <Button variant='outline' color='error.3' onClick={() => context.closeModal(id)} leftSection={<IconCloseDeleteNo height='1em' />}>
          Cancel
        </Button>
        <Button variant='outline' color='success.3' leftSection={<IconFASave height='1em' />} onClick={saveContact}>
          Save
        </Button>
      </Flex>
    </ModalWrapper>
  )
}

type OpenModalForAddBeneficiaryProps = {
  onClose?: () => void
}
export function openModalForAddBeneficiary(props?: OpenModalForAddBeneficiaryProps) {
  return () =>
    modals.openContextModal({
      modal: 'addBeneficiary',
      withCloseButton: false,
      size: '55rem',
      onClose: props?.onClose,
      innerProps: {},
    })
}

export default AddBeneficiary