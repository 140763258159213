import { Button, Paper, Stack, TextInput } from '@mantine/core'
import { IconArchive, IconSearch } from '../../../assets/icons'
import { HeaderSecondary } from '../../../components/common/HeaderSecondary'
import { SearchableSelect, usePropsForCountrySelect } from '../../../components/input/SearchableSelect'

const SearchIndividual = ({
  countryFilter = '',
  setCountryFilter,
  statusFilter,
  setStatusFilter,
}: {
  countryFilter: string
  setCountryFilter: (val: string) => void
  statusFilter: string | null
  setStatusFilter: (val: string | null) => void
}) => {
  const propsForCountrySelect = usePropsForCountrySelect()

  return (
    <Paper radius='md' shadow='cardnoir' p='lg' bg='dark.8' miw={392}>
      <HeaderSecondary c='white' pb='1rem' pt='0' ta='center'>
        Search individual beneficiaries
      </HeaderSecondary>

      <Stack gap='lg'>
        <TextInput 
          label='Filter by name or alias' 
          style={{ flexGrow: 1 }} 
          rightSection={<IconSearch height='1.1em' />} 
        />

        <SearchableSelect 
          {...propsForCountrySelect} 
          label='Filter by country' 
          value={countryFilter} 
          onChange={(v) => setCountryFilter(v)} 
        />

        <Button
          variant='outline'
          color='dark.0'
          px='1rem'
          leftSection={<IconArchive height='1em' />}
          onClick={() => setStatusFilter(statusFilter === 'active' ? 'inactive' : 'active')}
        >
          Archived and disabled beneficiaries
        </Button>
      </Stack>
    </Paper>
  )
}

export default SearchIndividual