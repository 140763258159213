import { Box, Button, Checkbox, Chip, Divider, Flex, Grid, Group, NumberInput, Paper, TextInput } from '@mantine/core'
import { DateInput } from '@mantine/dates'
import { useContext, useState } from 'react'
import { useLocation } from 'wouter'
import { IconCloseDeleteNo, IconSearch, IconStatementSaveExport } from '../../../../assets/icons'
import ChatButton from '../../../../components/ChatButton/ChatButton'
import { SessionContext } from '../../../../contexts'
import { Account, Transaction, User } from '../../../../utils/interfaces'
import classes from './TimelineWrapper.module.css'
import Timeline from '../Timeline/Timeline'

const TimelineWrapper = ({ account, user }: { account: Account; user: User }) => {
  const { session } = useContext(SessionContext)
  const [, navigate] = useLocation()

  const allValues = ['all', 'spent', 'loaded', 'transfers']
  const [selected, _setSelected] = useState(['all', 'spent', 'loaded', 'transfers'])
  const [showDateFilter, setShowDateFilter] = useState(false)
  const [showAmountFilter, setShowAmountFilter] = useState(false)

  const applyAllLogic = (previousValue: string[], newValue: string[]) => {
    if (previousValue.includes('all') && !newValue.includes('all')) {
      return []
    }
    if (!previousValue.includes('all') && newValue.includes('all')) {
      return allValues
    }
    if (previousValue.includes('all') && newValue.includes('all')) {
      if (newValue.length !== allValues.length) {
        return newValue.filter((v) => v !== 'all')
      }
    }
    if (!newValue.includes('all')) {
      if (newValue.length === allValues.length - 1) {
        return ['all', ...newValue]
      }
    }

    return newValue
  }

  const setSelected = (valueFromChips: string[]) => {
    return _setSelected((prev) => applyAllLogic(prev, valueFromChips))
  }

  const originalTransactions =
    account !== undefined
      ? session.transactions.filter((t) => t.account_id === account.id).map((t) => ({ ...t, account: account }))
      : user.accounts.reduce(
          (prev, current) => [...prev, ...session.transactions.filter((t) => t.account_id === current.id).map((t) => ({ ...t, account: current }))],
          [] as Transaction[]
        )

  // TODO: showing mocked transactions if none is present
  const transactions = originalTransactions.length === 0 ? session.transactions.map((t) => ({ ...t, account: user.accounts[0] })) : originalTransactions

  return (
    <Box>
      <Paper p="md" bg="dark.8" shadow="cardnoir" radius="md">
        <Flex justify="space-between" align="center" ff="heading" fw={600} fz="lg">
          <Box>Statement Centre</Box>
          <Button variant="subtle" size="xs" leftSection={<IconStatementSaveExport height="1.3em" />} color="success" pl="4px" pr="4px">
            Save/Export
          </Button>
        </Flex>

        <Divider size="sm" color="success" my="xs" />
        
        <Grid gutter="md">
          <Grid.Col span={{ base: 12 }}>
            <Grid gutter="md">
              <Grid.Col span={{ base: 12, md: 9 }}>
                <TextInput label="Filter transactions" type="text" placeholder="Search by beneficiary, reference..." color="success" />
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 3 }}>
                <Group gap="md" pt={{ md: '1.4rem' }}>
                  <Checkbox
                    label="By date"
                    variant="outline"
                    color="success"
                    styles={{ label: { paddingLeft: '0.5rem' } }}
                    checked={showDateFilter}
                    onChange={() => setShowDateFilter(!showDateFilter)}
                  />
                  <Checkbox
                    label="By amount"
                    variant="outline"
                    color="success"
                    styles={{ label: { paddingLeft: '0.5rem' } }}
                    checked={showAmountFilter}
                    onChange={() => setShowAmountFilter(!showAmountFilter)}
                  />
                </Group>
              </Grid.Col>
            </Grid>
          </Grid.Col>

          {showDateFilter && (
            <Grid.Col span={12}>
              <Grid gutter="md">
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <DateInput label="From date" clearable placeholder="from date. eg. 25 Sep 2023" valueFormat="DD MMMM YYYY" />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <DateInput label="To date" clearable placeholder="to date. eg. 25 Oct 2023" valueFormat="DD MMMM YYYY" />
                </Grid.Col>
              </Grid>
            </Grid.Col>
          )}

          {showAmountFilter && (
            <Grid.Col span={12}>
              <Grid gutter="md">
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <NumberInput label="From amount" placeholder="min amount. eg. 2000" hideControls />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6 }}>
                  <NumberInput label="To amount" placeholder="max amount. eg. 10000" hideControls />
                </Grid.Col>
              </Grid>
            </Grid.Col>
          )}

          <Grid.Col span={12}>
            <Flex gap="sm" justify={{ md: 'space-between' }} align={{ base: 'center' }} direction={{ base: 'column', md: 'row' }}>
              <Chip.Group multiple={true} value={selected} onChange={setSelected}>
                <Flex gap={{ base: 6, md: 'xs' }} justify="center">
                  <Chip value="all" radius="sm" size="sm" color="success.6" autoContrast>
                    All
                  </Chip>
                  <Chip value="spent" radius="sm" size="sm" color="success" autoContrast>
                    spent
                  </Chip>
                  <Chip value="loaded" radius="sm" size="sm" color="success" autoContrast>
                    loaded
                  </Chip>
                  <Chip value="transfers" radius="sm" size="sm" color="success" autoContrast>
                    transfers
                  </Chip>
                </Flex>
              </Chip.Group>
              <Group gap="xs">
                <Button size="xs" leftSection={<IconCloseDeleteNo height="0.9em" />} color="primary.5" variant="outline" autoContrast>
                  Clear filters
                </Button>
                <Button size="xs" leftSection={<IconSearch height="1em" />} autoContrast>
                  Filter transactions
                </Button>
              </Group>
            </Flex>
          </Grid.Col>
        </Grid>

        <Divider size="sm" color="success" my="md" />
        <Timeline transactions={transactions} />
      </Paper>

      <ChatButton className={classes.chatButton} />
    </Box>
  )
}

export default TimelineWrapper