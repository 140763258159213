import { ActionIcon, Button, Chip, Input, InputWrapper, createTheme } from '@mantine/core'
import classes from './Theme.module.css'

/**
 * Custom theme configuration for Mantine UI
 * Defines colors, typography, spacing, breakpoints, and component styles
 */
export default createTheme({
  // Base theme configuration
  primaryShade: 6,
  primaryColor: 'primary',
  fontFamily: '"Raleway", sans-serif',
  cursorType: 'pointer',
  white: '#f9f6f6',
  black: '#080808',

  // Color palette definition
  colors: {
    // Primary brand color with variants
    primary: [
      '#feedd2',
      '#fee3b9',
      '#fdd9a0',
      '#fdd087', // primaryHighlight
      '#fcc66e',
      '#fcbc55',
      '#fbb03b', // default
      '#f49d10',
      '#c17d10',
      '#8e5e10', // primaryMuted
    ],

    // Success color palette
    success: [
      '#cce7c0',
      '#bde0ae',
      '#aed99b',
      '#9fd289', // successHighlight
      '#90cb76',
      '#81c464',
      '#72bd51', // default
      '#5a9f3c',
      '#457a2e',
      '#305520',
    ],

    // Error color palette
    error: [
      '#fbbbbd',
      '#faa3a7',
      '#f99093',
      '#fa7a7e',
      '#ff5257',
      '#ff242b',
      '#da0b12',
      '#c20a10',
      '#9b080d',
      '#7f0a0e',
    ],

    // Neutral gray with reddish tint
    nebulous: [
      '#f9f6f6', // textPrimary
      '#f4f0f0',
      '#f0eaea',
      '#e7dfdf',
      '#d9cece',
      '#c5baba',
      '#aba1a0', // default
      '#8d8281',
      '#6d6564',
      '#444140',
    ],
  },

  // Spacing scale
  spacing: {
    xxs: 'calc(0.5rem * var(--mantine-scale))',
    xs: 'calc(0.625rem * var(--mantine-scale))',
    sm: 'calc(0.75rem * var(--mantine-scale))',
    md: 'calc(1rem * var(--mantine-scale))',
    lg: 'calc(1.25rem * var(--mantine-scale))',
    xl: 'calc(1.5rem * var(--mantine-scale))',
    xxl: 'calc(2rem * var(--mantine-scale))',
    xxxl: 'calc(2.5rem * var(--mantine-scale))',
  },

  // Breakpoints for responsive design
  breakpoints: {
    xs: '25.875em', // ~414px
  },

  // Typography configuration
  headings: {
    fontFamily: '"Raleway", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji',
    fontWeight: '700',
    sizes: {
      h1: { fontSize: '2rem', lineHeight: '1.3', fontWeight: undefined },
      h2: { fontSize: '1.5rem', lineHeight: '1.35', fontWeight: undefined },
      h3: { fontSize: '1.125rem', lineHeight: '1.4', fontWeight: undefined },
    },
  },

  // Component-specific styles
  components: {
    InputWrapper: InputWrapper.extend({
      defaultProps: {
        inputWrapperOrder: ['label', 'input', 'description', 'error'],
      },
      classNames: { error: classes.inputWrapperError },
    }),
    Input: Input.extend({
      classNames: { input: classes.input },
    }),
    Button: Button.extend({
      classNames: {
        root: classes.button,
        inner: classes.buttonInner,
        section: classes.buttonSection,
        label: classes.buttonLabel,
      },
    }),
    ActionIcon: ActionIcon.extend({
      classNames: { root: classes.actionIcon },
    }),
    Chip: Chip.extend({
      styles: { input: { position: 'absolute' } },
    }),
  },

  // Shadow configurations
  shadows: {
    // Custom card shadows
    cardnoir: '0 0 3px 0 rgba(255, 255, 255, 0.2), 0 0 15px 3px rgba(255, 255, 255, 0.15), 0 0 20px -3px rgba(255, 255, 255, 0.1)',
    'cardnoir-lg': '0 0 6px 0 rgba(255, 255, 255, 0.2), 0 0 22px 5px rgba(255, 255, 255, 0.15), 0 0 25px -7px rgba(255, 255, 255, 0.1)',

    // Standard shadow scale
    xs: '0 calc(0.0625rem * var(--mantine-scale)) calc(0.1875rem * var(--mantine-scale)) rgba(255, 255, 255, 0.05), 0 calc(0.0625rem * var(--mantine-scale)) calc(0.125rem * var(--mantine-scale)) rgba(255, 255, 255, 0.1)',
    sm: '0 calc(0.0625rem * var(--mantine-scale)) calc(0.1875rem * var(--mantine-scale)) rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05) 0 calc(0.625rem * var(--mantine-scale)) calc(0.9375rem * var(--mantine-scale)) calc(-0.3125rem * var(--mantine-scale)), rgba(255, 255, 255, 0.04) 0 calc(0.4375rem * var(--mantine-scale)) calc(0.4375rem * var(--mantine-scale)) calc(-0.3125rem * var(--mantine-scale))',
    md: '0 calc(0.0625rem * var(--mantine-scale)) calc(0.1875rem * var(--mantine-scale)) rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05) 0 calc(1.25rem * var(--mantine-scale)) calc(1.5625rem * var(--mantine-scale)) calc(-0.3125rem * var(--mantine-scale)), rgba(255, 255, 255, 0.04) 0 calc(0.625rem * var(--mantine-scale)) calc(0.625rem * var(--mantine-scale)) calc(-0.3125rem * var(--mantine-scale))',
    lg: '0 calc(0.0625rem * var(--mantine-scale)) calc(0.1875rem * var(--mantine-scale)) rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05) 0 calc(1.75rem * var(--mantine-scale)) calc(1.4375rem * var(--mantine-scale)) calc(-0.4375rem * var(--mantine-scale)), rgba(255, 255, 255, 0.04) 0 calc(0.75rem * var(--mantine-scale)) calc(0.75rem * var(--mantine-scale)) calc(-0.4375rem * var(--mantine-scale))',
    xl: '0 calc(0.0625rem * var(--mantine-scale)) calc(0.1875rem * var(--mantine-scale)) rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05) 0 calc(2.25rem * var(--mantine-scale)) calc(1.75rem * var(--mantine-scale)) calc(-0.4375rem * var(--mantine-scale)), rgba(255, 255, 255, 0.04) 0 calc(1.0625rem * var(--mantine-scale)) calc(1.0625rem * var(--mantine-scale)) calc(-0.4375rem * var(--mantine-scale))',
  },
})