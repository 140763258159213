import { ActionIcon, Box, Collapse, Divider, Flex, Grid } from '@mantine/core';
import dayjs from 'dayjs';
import { useState } from 'react';
import { IconArrowDropDown } from '../../../../assets/icons';
import { currencyFormat } from '../../../../backend/currencies';
import TransactionIcon from '../../../../components/TransactionIcon';
import { descriptionMap, getDate, getTransactionType, properName } from '../../../../components/transactionUtils';
import { Transaction } from '../../../../utils/interfaces';
import classes from './Timeline.module.css';

const TransactionItem = ({ transaction }: { transaction: Transaction }) => {
  const [isOpened, setIsOpened] = useState(false);
  const transactionType = getTransactionType(transaction);

  return (
    <Box className={classes.timelineItem} onClick={() => setIsOpened((v) => !v)}>
      <Grid py='sm' px='sm' align='center' gutter='sm'>
        <Grid.Col span='auto'><TransactionIcon transactionType={transactionType} /></Grid.Col>
        <Grid.Col span='auto'>
          <Box c='primary.3'>{descriptionMap(transactionType)}</Box>
          <Box fw='500'>{transaction.counterparty.name}</Box>
        </Grid.Col>
        <Grid.Col span='content'>
          <Flex w='max-content' fw='700' ta='right' gap='xxs'>
            {currencyFormat.format(transaction.amount) + '  ' + transaction.currency}
          </Flex>
        </Grid.Col>
        <Grid.Col span='content'>
          <ActionIcon variant='transparent' color='primary.6'>
            <Box component={IconArrowDropDown} h={12} style={{ transform: isOpened ? 'rotate(180deg)' : '', transition: 'transform 0.3s ease-in-out' }} />
          </ActionIcon>
        </Grid.Col>
      </Grid>

      <Collapse in={isOpened}>
        <Box mx='sm' mb='sm' p='sm' fz='sm' c='nebulous.0' style={{ border: '1pt dashed var(--mantine-color-primary-9)' }}>
          <Grid>
            <Grid.Col span={6}>Transaction status</Grid.Col>
            <Grid.Col span={6} ta='right' tt='capitalize'>{transaction.status}</Grid.Col>
            <Grid.Col span={6}>Transaction type</Grid.Col>
            <Grid.Col span={6} ta='right'>{properName(transactionType)}</Grid.Col>
            {transactionType.startsWith('INTERNAL_') || transaction.amount > 0 ? (
              <>
                <Grid.Col span={6}>Estimated arrival</Grid.Col>
                <Grid.Col span={6} ta='right'>{dayjs(transaction.created_at).format('D MMM YYYY')}</Grid.Col>
                <Grid.Col span={6}>Reference</Grid.Col>
                <Grid.Col span={6} ta='right'>{transaction.reference}</Grid.Col>
              </>
            ) :
              <>
                <Grid.Col span={6}>Date</Grid.Col>
                <Grid.Col span={6} ta='right'>{dayjs(transaction.created_at).format('D MMM YYYY')}</Grid.Col>
                <Grid.Col span={6}>Time (local)</Grid.Col>
                <Grid.Col span={6} ta='right'>{dayjs(transaction.created_at).format('HH:mm')}</Grid.Col>
              </>
            }
            <Grid.Col span={6}>Transaction number</Grid.Col>
            <Grid.Col span={6} ta='right'>{transaction.id}</Grid.Col>
          </Grid>
        </Box>
      </Collapse>
      <Divider size='xs' color='nebulous.6' />
    </Box>
  );
};

const Timeline = ({ transactions }: { transactions: Transaction[] }) => {
  const displayedTransactions: { [key: string]: Transaction[] } = {};
  transactions.sort((f, s) => (dayjs(f.created_at).isBefore(dayjs(s.created_at)) ? 1 : -1));

  for (const tx in transactions) {
    displayedTransactions[getDate(transactions[tx].created_at)] = [...(displayedTransactions[getDate(transactions[tx].created_at)] || []), transactions[tx]];
  }

  return (
    <Box pb='md' pl='xs'>
      <Box pos='relative'>
        <Box pos='absolute' left={-4} bottom={0} top={0} w={4} bg='nebulous.0' />
        {Object.keys(displayedTransactions).map((date) => (
          <Box key={date}>
            <Box className={classes.transactionItem}>
              <Box ml='md' pb='xxs'>{date}</Box>
            </Box>
            <Divider size='xs' color='nebulous.6' ml='md' />
            {displayedTransactions[date].map((transaction) => (
              <TransactionItem key={transaction.id} transaction={transaction} />
            ))}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Timeline;